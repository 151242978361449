import React, { useState, useEffect } from "react";
import axios from "axios";

const ViewApplication = ({ setShowDetails, teamId }) => {
  const [appData, setAppData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_Applications;

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/${teamId}`);
        setAppData(response.data.body);
      } catch (error) {
        console.error("Error fetching team details:", error);
      }
    };

    fetchTeamDetails();
    return () => {
      setAppData(null);
    };
  }, [teamId]);

  if (!appData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">View Team Details</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowDetails(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Go Back
          </button>
        </div>
      </div>
      <div className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]">
        <div className="grid grid-cols-2 gap-y-4">
          <p className="font-bold">App ID</p>
          <p>{appData.id}</p>
          <p className="font-bold">Application Name</p>
          <p>{appData.name}</p>
          <p className="font-bold">App Type</p>
          <p>{appData.app_type}</p>
        </div>
      </div>
    </>
  );
};

export default ViewApplication;
