import React from "react";

const DeleteConfirmationPopup = ({ onCancel, onConfirm }) => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-[gray] bg-opacity-50">
      <div className="bg-[white] p-8 rounded-md shadow-md ">
        <p className="text-lg font-bold mb-4">
          Are you sure you want to delete this record?
        </p>
        <div className="flex justify-center">
          <button
            className="bg-[red] text-[white] px-4 py-2 mr-4 rounded-md"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="bg-[gray] text-[white] px-4 py-2 rounded-md"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
