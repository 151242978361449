import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { RiLoginBoxFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import Profile from "./profile";
import AddOTP from "./addotp";
import Inbox from "./inbox";
import ChangePassword from "../changePassword";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedItem, setSelectedItem] = useState("inbox");
  const [showProfile, setShowProfile] = useState(false);
  const [showAddOtp, setShowAddOtp] = useState(false);
  const [showInbox, setShowInbox] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser) || {};
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setNav(!isMobile);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleOpenChangePassword = () => {
    const dropdown = document.querySelector(".dropdown-content");
    if (dropdown) {
      dropdown.style.display = "none";
    }
    setShowChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    const dropdown = document.querySelector(".dropdown-content");
    if (dropdown) {
      dropdown.style.display = "block";
    }
    setShowChangePassword(false);
  };

  const menuItems = [
    {
      icon: <RiLoginBoxFill size={25} className="mr-4" />,
      text: "Inbox",
      id: "inbox",
    },
    {
      icon: <MdDashboard size={25} className="mr-4" />,
      text: "Dashboard",
      id: "dashboard",
    },
    {
      icon: <IoMdAdd size={25} className="mr-4" />,
      text: "Run Test",
      id: "addotp",
    },
  ];

  const handleItemClick = (itemId) => {
    setSelectedItem(itemId);
    setShowProfile(itemId === "dashboard");
    setShowAddOtp(itemId === "addotp");
    setShowInbox(itemId === "inbox");
  };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  return (
    <div>
      {!isMobile && (
        <nav>
          <ul className="flex flex-col p-4 text-gray-800 bg-white fixed top-0 left-0 w-[300px] h-screen bg-gray-200 z-10 duration-300">
            <h2 className="text-2xl p-4 text-[#0B63F8] ">
              EON <h2>Testing Tool</h2>
            </h2>
            {menuItems.map(({ icon, text, id }, index) => {
              return (
                <div key={index} className=" py-4">
                  <li
                    className={`text-xl flex cursor-pointer rounded-full mx-auto p-2 hover:text-white hover:bg-black ${
                      selectedItem === id ? "bg-blueText-light" : ""
                    }`}
                    onClick={() => handleItemClick(id)}
                  >
                    {icon} {text}
                  </li>
                </div>
              );
            })}
          </ul>
        </nav>
      )}

      <div className="max-w-[1640px] mx-auto">
        <div
          className={`flex justify-between items-center p-4 shadow-sm relative bg-[#F8F8F8] ${
            !isMobile && "ml-[300px]"
          }`}
        >
          {isMobile && (
            <>
              <div
                className={
                  nav
                    ? "bg-black/80 fixed w-full h-screen z-10 top-0 left-0"
                    : "hidden "
                }
                onClick={() => setNav(false)}
              ></div>
              <div
                className={
                  nav
                    ? " bg-[#FFFFFF] fixed top-0 left-0 w-[300px] h-screen bg-gray-200 z-10 duration-300"
                    : "  bg-[#FFFFFF] fixed top-0 left-[-300px] w-[300px] h-screen bg-gray-200 z-10 duration-300 "
                }
              >
                <AiOutlineClose
                  onClick={() => setNav(false)}
                  size={30}
                  className="absolute right-4 top-4 cursor-pointer"
                />
                <h2 className="text-2xl p-4 text-blueText ">
                  EON <h2>Testing Tool</h2>
                </h2>
                <nav>
                  <ul className="flex flex-col p-4 text-gray-800">
                    {menuItems.map(({ icon, text, id }, index) => {
                      return (
                        <div key={index} className=" py-4">
                          <li
                            className={`text-xl flex cursor-pointer rounded-full mx-auto p-2 hover:text-white hover:bg-black ${
                              selectedItem === id ? "bg-blueText-light" : ""
                            }`}
                            onClick={() => handleItemClick(id)}
                          >
                            {icon} {text}
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </>
          )}

          <div className=" flex items-center pl-8 ">
            {isMobile && (
              <div onClick={() => setNav(!nav)} className="cursor-pointer">
                <AiOutlineMenu size={24} />
              </div>
            )}
          </div>

          <div className="flex items-center">
            <details className="dropdown z-[100000]">
              <summary className="m-1 btn">
                <span className="text-[black] text-sm ml-1">{user.name}</span>
                <FaUserCircle
                  size={24}
                  color="#0B63F8"
                  className="m-2 cursor-pointer"
                />
              </summary>
              <ul className="p-1 shadow menu dropdown-content z-[1] rounded-sm bg-base-100 w-38">
                {user.role && (
                  <li className="p-2">
                    <span className="text-xs">Role: {user.role}</span>
                  </li>
                )}
                <li className="p-2">
                  <a className="text-xs" onClick={handleOpenChangePassword}>
                    Change Password
                  </a>
                </li>
                <li className="p-2">
                  <a className="text-xs" onClick={handleLogout}>
                    Log Out
                  </a>
                </li>
              </ul>
            </details>
            {showChangePassword && (
              <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[gray] bg-opacity-50 change-password-popup ">
                <div className="change-password-popup ">
                  <div className="bg-[white] p-6 rounded-lg shadow-md relative ">
                    <button
                      onClick={handleCloseChangePassword}
                      className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                    >
                      &times;
                    </button>
                    <h2 className="text-xl font-semibold mb-4">
                      Change Password
                    </h2>
                    <ChangePassword
                      setShowChangePassword={setShowChangePassword}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {!isMobile && (
          <div className="ml-[300px] bg-[#F8F8F8] h-svh">
            {showProfile && (
              <Profile
                nav={nav}
                setShowProfile={setShowProfile}
                setSelectedItem={setSelectedItem}
              />
            )}
            {showAddOtp && (
              <AddOTP
                nav={nav}
                setShowProfile={setShowProfile}
                setSelectedItem={setSelectedItem}
              />
            )}
            {showInbox && (
              <Inbox
                nav={nav}
                setShowProfile={setShowProfile}
                setSelectedItem={setSelectedItem}
              />
            )}
          </div>
        )}
        {isMobile && (
          <div className=" bg-[#F8F8F8] h-svh">
            {showProfile && (
              <Profile
                nav={nav}
                setShowProfile={setShowProfile}
                setSelectedItem={setSelectedItem}
              />
            )}
            {showAddOtp && (
              <AddOTP
                nav={nav}
                setShowProfile={setShowProfile}
                setSelectedItem={setSelectedItem}
              />
            )}
            {showInbox && (
              <Inbox
                nav={nav}
                setShowInbox={setShowInbox}
                setSelectedItem={setSelectedItem}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
