import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to install axios with npm or yarn

const ViewTeamDetails = ({ setShowSelectNumber, numbers }) => {
  const [sites, setSites] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    // Function to fetch sites from the API
    const fetchSites = async () => {
      try {
        const response = await axios.get(
          "https://arvuc8x0kj.execute-api.us-east-1.amazonaws.com/dev/applications-for-testers"
        );
        const apiSites = response.data.body.map((site) => ({
          id: site.id,
          name: site.name,
          selected: false,
        }));
        setSites(apiSites);
      } catch (error) {
        console.error("Error fetching sites from API:", error);
      }
    };

    fetchSites();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleToggle = (id) => {
    setSites((prevSites) =>
      prevSites.map((site) =>
        site.id === id ? { ...site, selected: !site.selected } : site
      )
    );
  };
  const handleToggleAll = () => {
    setSelectAll(!selectAll);
    setSites((prevSites) =>
      prevSites.map((site) => ({
        ...site,
        selected: !selectAll,
      }))
    );
  };
  const handleDownloadCSV = () => {
    const selectedSites = sites.filter((site) => site.selected);
    const selectedSiteNames = selectedSites.map((site) => site.name);
    let csvData = `Number,${selectedSiteNames.join(",")}\n`;
    numbers.forEach((number) => {
      let rowData = `${number},`;
      selectedSites.forEach(() => {
        rowData += ",";
      });
      csvData += `${rowData}\n`;
    });
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser) || {};
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB");
    link.download = `${user.name}-${formattedDate}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  const splitSitesIntoThree = (sitesArray) => {
    const oneThirdIndex = Math.ceil(sitesArray.length / 3);
    const twoThirdIndex = 2 * oneThirdIndex;
    return [
      sitesArray.slice(0, oneThirdIndex),
      sitesArray.slice(oneThirdIndex, twoThirdIndex),
      sitesArray.slice(twoThirdIndex),
    ];
  };

  const [firstColumnSites, secondColumnSites, thirdColumnSites] =
    splitSitesIntoThree(sites);

  return (
    <div className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]">
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Select Sites</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowSelectNumber(!true)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Cancel
          </button>
        </div>
      </div>
      <div  className="flex justify-end mt-4 pr-10">
      <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="toggle toggle-primary toggle-xs"
            checked={selectAll}
            onChange={handleToggleAll}
          />
          <span className="ml-2 text-gray-500">Select All</span>
        </label>
        </div>
      <div className=" pl-10 pr-10  grid grid-cols-3 gap-4">
        {[firstColumnSites, secondColumnSites, thirdColumnSites].map(
          (columnSites, columnIndex) => (
            <div key={`column-${columnIndex}`}>
              {columnSites.map((site) => (
                <div key={site.id} className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="toggle toggle-primary toggle-xs"
                      checked={site.selected}
                      onChange={() => handleToggle(site.id)}
                    />
                    <span
                      onClick={() => handleToggle(site.id)}
                      className={`ml-2 ${
                        site.selected ? "text-green-500" : "text-gray-500"
                      } cursor-pointer`}
                    >
                      {site.name}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          )
        )}
      </div>
      <div className="flex justify-end mt-4 pr-10">
        <button
          onClick={handleDownloadCSV}
          className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white]"
        >
          Download as CSV
        </button>
      </div>
    </div>
  );
};

export default ViewTeamDetails;
