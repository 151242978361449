import React, { useState, useEffect } from "react";
import TestsitesManually from "./testSitesManually";
import axios from "axios";

const RunTest = ({ setShowProfile, setSelectedItem }) => {
  const countriesUrl = process.env.REACT_APP_API_UpdatedCountries;
  const apiUrl = process.env.REACT_APP_API_MobileOperators;
  const simApiUrl = process.env.REACT_APP_API_SIMCards;
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [nextClicked, setNextClicked] = useState(false);
  const [showSelectNumbers, setShowSelectNumber] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [operators, setOperators] = useState([]);
  const [operatorDisabled, setOperatorDisabled] = useState(true);

  const handleNextClick = async () => {
    setNextClicked(true);
    setShowSelectNumber(true);
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);

    try {
      const response = await axios.post(`${simApiUrl}?lock=true`, {
        user_id: user.userId,
        sims_id: selectedNumbers,
      });
      setShowSelectNumber(true);
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const handleCountryChange = async (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    setOperatorDisabled(false);

    try {
      const response = await axios.get(`${apiUrl}/?country=${selectedCountry}`);
      setOperators(response.data.body);
      setSelectedOption(""); // Reset selected operator when changing country
    } catch (error) {
      console.error("Error fetching operators for the country:", error);
    }
  };

  const handleDropdownChange = async (e) => {
    const selectedOptionId = e.target.value;
    setSelectedOption(selectedOptionId);
    try {
      const response = await axios.get(`${simApiUrl}?mno=${selectedOptionId}`);
      const numbers = response.data.body.map((phoneNumber) => phoneNumber.id);
      const phoneNumbers = response.data.body.map(
        (phoneNumber) => phoneNumber.msisdn
      );
      setPhoneNumbers(response.data.body);
      setNumbers(phoneNumbers);
      setSelectedNumbers(numbers);
    } catch (error) {
      console.error("Error fetching phone numbers:", error);
    }
  };

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get(countriesUrl);
        setCountries(response.data.body);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchDropdownOptions();
  }, [apiUrl]);

  return (
    <>
      {showSelectNumbers ? (
        <TestsitesManually
          setShowSelectNumber={setShowSelectNumber}
          numbers={numbers}
        />
      ) : (
        <div className="ml-10 mr-10 pl-10 pr-10 pb-10 bg-[#FFFFFF]">
          <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
            <h2 className="text-2xl font-bold">Select Mobile Operator</h2>
            <div className="flex items-center">
              <button
                onClick={() => setShowProfile("dashboard")}
                className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="pl-10 pr-10 mb-4">
            <select
              value={selectedCountry}
              onChange={handleCountryChange}
              className="select select-bordered w-full max-w-xs"
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="pl-10 pr-10 mb-4">
            <select
              value={selectedOption}
              onChange={handleDropdownChange}
              className="select select-bordered w-full max-w-xs"
              disabled={operatorDisabled}
            >
              <option value="">Select Operator</option>
              {operators.map((operator) => (
                <option key={operator.id} value={operator.id}>
                  {operator.name}
                </option>
              ))}
            </select>
          </div>

          <div className="pl-10 pr-10">
            <h1 className="p-2 font-bold text-md">
              Numbers assigned to you for today:
            </h1>
            <ul className="list-disc pl-6">
              {phoneNumbers.map((phoneNumber) => (
                <li key={phoneNumber.id}>{phoneNumber.msisdn}</li>
              ))}
            </ul>
          </div>

          <div className="flex justify-end mt-4 pr-10">
            <button
              onClick={handleNextClick}
              disabled={!selectedOption}
              className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white]"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RunTest;
