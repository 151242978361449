import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const signinAPI = process.env.REACT_APP_API_Signin;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (!email || !password) {
      setError("Please enter email and password");
    } else {
      try {
        const response = await fetch(signinAPI, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.body.roleId === 1) {
          navigate("/dashboard");
          window.location.reload();
        } else if (data.body.roleId === 2) {
          navigate("/teamleadDashboard");
          window.location.reload();
        } else if (data.body.roleId === 3) {
          navigate("/testerDashboard");
          window.location.reload();
        } else {
          setError("Invalid email or password");
        }
        var now = new Date().getTime();
        localStorage.setItem("setupTime", now);
        localStorage.setItem("user", JSON.stringify(data.body));
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred while signing in");
      }
    }
  };

  return (
    <div>
      <div className="min-h-screen flex justify-center items-center bg-[#e5e7eb]">
        <div className="flex flex-col sm:flex-row w-full  justify-center min-h-screen">
          <div className="p-14 bg-[white] mx-auto rounded-2xl w-full max-w-md sm:max-w-none sm:w-1/2 flex flex-col justify-center items-center">
            <div className="mb-8 text-center">
              <h3 className="font-semibold text-3xl text-[#013CC6]">
                EON Testing Tool
              </h3>
              <p className="text-[gray] mt-5">
                Welcome back! Please login to your account.
              </p>
            </div>
            <form onSubmit={handleLogin}>
              <div className="space-y-3 w-full  flex flex-col items-center">
                <div className="flex flex-col items-start">
                  <label className="text-sm font-medium text-gray-700 tracking-wide">
                    Email
                  </label>
                  <input
                    className="w-full sm:w-80 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-400"
                    type="email"
                    placeholder="mail@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className=" space-y-3 flex flex-col items-center w-full md:w-1/2  mb-4 relative">
                  <label className="form-control relative text-sm font-medium text-gray-700 tracking-wide">
                    <span className="label-text">Password</span>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="w-full sm:w-80 px-4 py-2 border font-normal border-gray-300 rounded-lg focus:outline-none focus:border-green-400"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="absolute inset-y-0  right-0 top-5  flex items-center pr-4 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible
                          size={23}
                          className="text-[gray]"
                        />
                      ) : (
                        <AiOutlineEye size={25} className="text-[gray]" />
                      )}
                    </div>
                  </label>
                  {error && <p className="text-[red] text-xs mt-1">{error}</p>}
                </div>
                <div className="flex flex-col items-center">
                  <button
                    type="submit"
                    className="w-full sm:w-80 flex justify-center bg-[#013CC6]  mt-5 hover:bg-blue-500 text-[white] p-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </form>
            <div className="pt-5 text-center text-gray-400 text-xs">
              <span>
                Copyright © 2024{" "}
                <a
                  href="https://codepen.io/uidesignhub"
                  rel=""
                  target="_blank"
                  title="Ajimon"
                  className="text-green hover:text-green-500"
                >
                  EON
                </a>
              </span>
            </div>
          </div>
          <div className="hidden sm:flex w-full bg-gray-100 min-h-screen justify-center items-center sm:w-1/2">
            <div className="text-center text-gray-800">
              <img src="" className="mb-3" />
              <h1 className="mb-3 font-bold text-5xl text-[#013CC6]">
                EON Testing Tool
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
