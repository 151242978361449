import React, { useState, useEffect , useRef} from "react";
import { MdDelete } from "react-icons/md";
import { IoExpand } from "react-icons/io5";
import { FaEdit, FaSearch } from "react-icons/fa";
import AddApplication from "./addApplication";
import ViewApplication from "./viewApplication";
import axios from "axios";
import DeleteConfirmationPopup from "../deleteConfirmation";
import EditApplication from "./editApplication";
import CSVReader from "react-csv-reader";

const Applications = ({ nav }) => {
  const apiurl = process.env.REACT_APP_API_Applications;
  const teamsURL = process.env.REACT_APP_API_Team;
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [serialNumber, setSerialNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [importError, setImportError] = useState("");
  const [importSuccess, setImportSuccess] = useState("");
  const csvReaderRef = useRef(null);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = users.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.app_type.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const handleDeleteClick = async (userId) => {
    setShowDeleteConfirmation(true);
    setDeleteUserId(userId);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${apiurl}/${deleteUserId}`);
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== deleteUserId)
      );
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };
  const handleFile = async (data, fileInfo) => {
    setImportSuccess("");
    setImportError("");
    let importSuccessCount = 0;
    let importErrorCount = 0;

    // Assuming the endpoint and the request payload structure
    const apiEndpoint =
      "https://arvuc8x0kj.execute-api.us-east-1.amazonaws.com/dev/bulk-apps";

    // Map the CSV data to your API's expected format
    const formattedData = data.map((item) => ({
      app_type: item.app_type,
      name: item.name,
      visible:1
    }));

    // Send the bulk data to your API endpoint
    try {
      const response = await axios.post(apiEndpoint, formattedData);

      if (response.data.body == "Bulk Records Uploaded") {
        setImportSuccess(`Successfully imported all records.`);
      }
      // Assuming the API returns the number of successfully inserted records
      importSuccessCount = response.data.successCount;
      importErrorCount = data.length - importSuccessCount;
    } catch (error) {
      console.error("Bulk import error:", error);
      setImportError("An error occurred during the import process.");
      return;
    }

    // // Update UI based on the operation result
    // if (importSuccessCount > 0) {
    //   setImportSuccess(`Successfully imported ${importSuccessCount} records.`);
    // }
    // if (importErrorCount > 0) {
    //   setImportError(`Failed to import ${importErrorCount} records.`);
    // }
  };
  const handleDownloadSampleFile = () => {
    const sampleFileUrl =
      "https://sims-upload-sample-file.s3.amazonaws.com/BulkApplicationUploadSample.csv";
    const anchor = document.createElement("a");
    anchor.href = sampleFileUrl;
    anchor.download = "sample.csv";

    document.body.appendChild(anchor);
    anchor.click();

    // Cleanup
    document.body.removeChild(anchor);
  }; 
  useEffect(() => {
    fetchData();
  }, [showForm, setShowForm, showEditForm, setEditForm]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiurl);
      if (!response.ok) {
        throw new Error("Failed to fetch teams");
      }
      const data = await response.json();
      setUsers(data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    setShowForm(true);
  };

  const handleEditClick = async (userId) => {
    setEditForm(true);
    setSelectedUserId(userId);
  };

  const handleViewClick = async (userId) => {
    setShowDetails(true);
    setSelectedUserId(userId);
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="container mx-auto">
        {showForm ? (
          <AddApplication setShowForm={setShowForm} />
        ) : showEditForm ? (
          <EditApplication id={selectedUserId} setEditForm={setEditForm} />
        ) : showDetails ? (
          <ViewApplication
            teamId={selectedUserId}
            setShowDetails={setShowDetails}
          />
        ) : (
          <>
            <div className="flex flex-col md:flex-row justify-between items-center pt-10 mb-4 pl-10 pr-10">
              <h2 className="text-2xl font-bold">All Applications</h2>
              <div className="flex items-center mt-4 md:mt-0">
                <div className="search-bar">
                  <FaSearch className="translate-x-3 transform translate-y-8 text-[gray]" />
                  <input
                    type="text"
                    placeholder="Search"
                    className="pl-10 input input-bordered w-30 md:w-auto "
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="flex flex-col items-start ml-4">
                 
                  {/* Changed from 'ml-8' to 'ml-4' */}
                  <p className="text-[red]">{importError}</p>
                  <p className="text-[green]">{importSuccess}</p>
                </div>
                <label
                  htmlFor="csvFile"
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-6"
                >
                  Upload CSV
                </label>
                <CSVReader
                  onFileLoaded={handleFile}
                  inputId="csvFile"
                  inputStyle={{ display: "none" }}
                  parserOptions={{ header: true, skipEmptyLines: true }}
                />
                <button
                  onClick={handleAddClick}
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-6"
                >
                  Add Application
                </button>
                <button
                  onClick={handleDownloadSampleFile}
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md border-primaryColor text-primaryColor ml-6"
                >
                  Download Sample File
                </button>
              </div>
            </div>
            <div className="overflow-x-auto ml-10 mr-10 pl-10 pr-10 pb-10 bg-[#FFFFFF]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left">Serial No.</th>
                    <th className="px-4 py-2 text-left">Application Name</th>
                    <th className="px-4 py-2 text-left">App type</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredUsers.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        No applications found
                      </td>
                    </tr>
                  ) : (
                    filteredUsers.map((user, index) => (
                      <tr key={user.id}>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {serialNumber + index}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {user.name}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {user.app_type}
                        </td>
                        <td className="border-t border-b border-[#ADA7A7] px-4 py-2">
                          <div className="flex justify-center items-center space-x-2">
                            <button
                              className="text-blue-500 hover:text-blue-700"
                              onClick={() => handleViewClick(user.id)}
                            >
                              <IoExpand color="green" />
                            </button>
                            <button
                              className="text-green-500 hover:text-green-700"
                              onClick={() => handleEditClick(user.id)}
                            >
                              <FaEdit color="blue" />
                            </button>
                            <button
                              className="text-red-500 hover:text-red-700"
                              onClick={() => handleDeleteClick(user.id)}
                            >
                              <MdDelete color="red" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {showDeleteConfirmation && (
          <DeleteConfirmationPopup
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default Applications;
