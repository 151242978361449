import React, { useState, useEffect } from "react";
import axios from "axios";

const EditApplication = ({ id, setEditForm }) => {
  const apirUrl = process.env.REACT_APP_API_Applications;
  const [name, setName] = useState("");
  const [app_type, setApp_type] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    app_type: "",
    general: "",
  });

  useEffect(() => {
    fetchTeamInfo();
  }, []);

  const fetchTeamInfo = async () => {
    try {
      const response = await axios.get(`${apirUrl}/${id}`);
      const appData = response.data.body;
      setName(appData.name);
      setApp_type(appData.app_type);
    } catch (error) {
      console.error("Error fetching team info:", error);
    }
  };

  const handleTeamNameChange = (e) => {
    setName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleCountryChange = (e) => {
    setApp_type(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, app_type: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.trim() && !app_type.trim()) {
      newErrors.name = "Please enter the application name";
      newErrors.app_type = "Please select the app type";
      setErrors(newErrors);
      return;
    }
    if (!name.trim()) {
      newErrors.name = "Please enter the application name";
      setErrors(newErrors);
      return;
    }
    if (!app_type) {
      newErrors.app_type = "Please select the app type";
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.put(`${apirUrl}/${id}`, {
        name,
        app_type,
        visible: 1,
      });
      if (response.status === 200) {
        setEditForm(false);
      } else {
        throw new Error("Failed to edit application");
      }
    } catch (error) {
      console.error("Error editing application:", error);
      setErrors({ general: "Failed to edit application" });
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Edit Application</h2>
        <div className="flex items-center">
          <button
            onClick={() => setEditForm(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Cancel
          </button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]"
      >
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text text-base">Application Name</span>
          </div>
          <input
            type="text"
            placeholder="Enter Team Name"
            className="input input-bordered w-full max-w-xs"
            value={name}
            onChange={handleTeamNameChange}
          />
          {errors.name && <p className="text-[red] text-xs">{errors.name}</p>}
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text text-base">App Type</span>
          </div>
          <select
            className="select select-bordered w-full max-w-xs"
            value={app_type} // This binds the select's value to the app_type state.
            onChange={handleCountryChange} // Updates app_type state when a user selects a different option.
          >
            <option value="">Select Application type</option>
            <option value="Web">Web</option>
            <option value="Android">Android</option>
          </select>

          {errors.app_type && (
            <p className="text-[red] text-xs">{errors.app_type}</p>
          )}
        </label>
        {errors.general && (
          <p className="text-[red] text-xs">{errors.general}</p>
        )}
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
          >
            Edit Application
          </button>
        </div>
      </form>
    </>
  );
};

export default EditApplication;
