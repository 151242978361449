import React, { useState, useEffect } from "react";
import axios from "axios";

const ViewTesterDetails = ({ setShowDetails, testerId }) => {
  const [testerData, setTesterData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_Testers;

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/${testerId}`);
        setTesterData(response.data.body);
      } catch (error) {
        console.error("Error fetching team details:", error);
      }
    };

    fetchTeamDetails();
    return () => {
      setTesterData(null);
    };
  }, [testerId]);

  if (!testerData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Tester Details</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowDetails(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Go Back
          </button>
        </div>
      </div>
      <div className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]">
        <div className="grid grid-cols-2 gap-y-4">
          <p className="font-bold">First Name</p>
          <p>{testerData.first_name}</p>
          <p className="font-bold">Last Name</p>
          <p>{testerData.last_name}</p>
          <p className="font-bold">Email</p>
          <p>{testerData.email}</p>
          <p className="font-bold">Contact Number</p>
          <p>{testerData.contact_number}</p>
          <p className="font-bold">Gender</p>
          <p>{testerData.gender}</p>
        </div>
      </div>
    </>
  );
};

export default ViewTesterDetails;
