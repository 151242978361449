import React, { useState, useEffect } from "react";
import axios from "axios";

const ViewTeamDetails = ({ setShowDetails, id }) => {
  const [apiData, setApiData] = useState(null);
  const simCardsApi = process.env.REACT_APP_API_SIMCards;

  useEffect(() => {
    if (id) {
      const dataUrl = `${simCardsApi}/${id}`;
      fetch(dataUrl)
        .then((res) => res.json())
        .then((res) => {
          setApiData(res.body);
        });
    }
  }, [id]);
  if (!apiData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">View Sim Details</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowDetails(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Go Back
          </button>
        </div>
      </div>
      <div className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]">
        <div className="grid grid-cols-2 gap-y-4">
          <p className="font-bold">ID</p>
          <p>{apiData.id}</p>
          <p className="font-bold">Name</p>
          <p>{apiData.first_name + " " + apiData.last_name}</p>
          <p className="font-bold">Prefix</p>
          <p>{apiData.prefix}</p>
          <p className="font-bold">Operator</p>
          <p>{apiData.operator}</p>
          <p className="font-bold">ICCID</p>
          <p>{apiData.iccid}</p>
          <p className="font-bold">IMSI</p>
          <p>{apiData.imsi}</p>
          <p className="font-bold">MSISDN</p>
          <p>{apiData.msisdn}</p>
        </div>
      </div>
    </>
  );
};

export default ViewTeamDetails;
