import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

const UserTable = ({ nav }) => {
  const apiUrl = process.env.REACT_APP_API_SMSIN;

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serialNumber, setSerialNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = users.filter((user) => {
    return (
      (user.country &&
        user.country.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.operator_name &&
        user.operator_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.destination_number &&
        user.destination_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (user.sender_id &&
        user.sender_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.content &&
        user.content.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.smsc &&
        user.smsc.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.timestamp && user.timestamp.includes(searchQuery.toLowerCase()))
    );
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/${user.userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch api data");
      }
      const data = await response.json();
      setUsers(data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="hero max-h-[5rem] flex items-center justify-center bg-[#ffc2c2]">
        <div className="hero-content text-center">
          <div className="max-w-screen">
            <p>
              <span className="text-2xl font-bold">Alert: </span>
              If you have multiple tests on the same number for same application
              then you won't be paid for it.
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <>
          <div className="flex flex-col md:flex-row justify-between items-center pt-10 mb-4 pl-10 pr-10">
            <h2 className="text-2xl font-bold">Messages details </h2>
            <div className="flex items-center mt-4 md:mt-0">
              <div className="form-control relative ml-4">
                <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search"
                  className="pl-10 input input-bordered w-30 md:w-auto"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div className="overflow-x-auto ml-10 mr-10 pl-10 pr-10 pb-10 bg-[#FFFFFF]">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Serial No.</th>
                  <th className="px-4 py-2 text-left">Timestamp</th>
                  <th className="px-4 py-2 text-left">Country</th>
                  <th className="px-4 py-2 text-left">Operator</th>
                  <th className="px-4 py-2 text-left">B Number</th>
                  <th className="px-4 py-2 text-left">A Number</th>
                  {/* <th className="px-4 py-2 text-left">Modem Type</th> */}
                  <th className="px-4 py-2 text-left">Contents</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="8" className="text-center py-4">
                      Loading...
                    </td>
                  </tr>
                ) : filteredUsers.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="text-center py-4">
                      No messages found for this user
                    </td>
                  </tr>
                ) : (
                  filteredUsers.map((user, index) => (
                    <tr key={user.id}>
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {serialNumber + index}
                      </td>
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.timestamp}
                      </td>
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.country}
                      </td>

                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.operator_name}
                      </td>

                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.destination_number}
                      </td>
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.sender_id}
                      </td>
                      {/* <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        ismart
                      </td> */}
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.content}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </>
      </div>
    </div>
  );
};

export default UserTable;
