import React, { useState, useEffect } from "react";

const ViewTeamDetails = ({ setShowProfile, setSelectedItem }) => {
  const apiUrl = process.env.REACT_APP_API_SMSIN;
  const [data, setData] = useState([]);
  const [dailyCount, setDailyCount] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);
  const [weeklyCount, setWeeklyCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const userId = user.userId;

      // Fetch daily count
      const dailyResponse = await fetch(
        `https://tg5n48dww1.execute-api.us-east-1.amazonaws.com/dev/otp-today/${userId}`
      );
      const dailyData = await dailyResponse.json();
      setDailyCount(dailyData.otp_today); // Assuming the API returns a JSON object with a 'count' property

      // Fetch weekly count
      const weeklyResponse = await fetch(
        `https://tg5n48dww1.execute-api.us-east-1.amazonaws.com/dev/otp-week/${userId}`
      );
      const weeklyData = await weeklyResponse.json();
      setWeeklyCount(weeklyData.otp_today);

      // Fetch monthly count
      const monthlyResponse = await fetch(
        `https://tg5n48dww1.execute-api.us-east-1.amazonaws.com/dev/otp-current-month/${userId}`
      );
      const monthlyData = await monthlyResponse.json();
      setMonthlyCount(monthlyData.otp_current_month);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Profile</h2>
        <div className="flex items-center"></div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mx-4 pl-10 ">
        <div className="card bg-base-100 shadow-xl max-w-md">
          <div className="card-body flex justify-between items-center">
            <div>
              <p className="font-bold text-3xl">{dailyCount}</p>
              <p className="text-lg">Received Tests Today</p>
            </div>
          </div>
        </div>
        {/* <div className="card bg-base-100 shadow-xl max-w-md">
          <div className="card-body flex justify-between items-center">
            <div>
              <p className="font-bold text-3xl">{weeklyCount}</p>
              <p className="text-lg">Generated OTPs This Week</p>
            </div>
          </div>
        </div> */}
        <div className="card bg-base-100 shadow-xl max-w-md">
          <div className="card-body flex justify-between items-center">
            <div>
              <p className="font-bold text-3xl">{monthlyCount}</p>
              <p className="text-lg">Received Tests this Month</p>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 shadow-xl max-w-md">
          <div className="card-body flex justify-between items-center">
            <div>
              <p className="font-bold text-3xl">{monthlyCount * 0.005} USD</p>
              <p className="text-lg">Payment for this month</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTeamDetails;
