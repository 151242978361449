import React, { useState, useEffect } from "react";
import axios from "axios";

const ViewTeamDetails = ({ setShowDetails, teamId }) => {
  const [teamData, setTeamData] = useState(null);
  const [country, setCountry] = useState("");
  const teamsURL = process.env.REACT_APP_API_Team;
  const countryAPI = process.env.REACT_APP_API_Countries;

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const response = await axios.get(`${teamsURL}/${teamId}`);
        setTeamData(response.data.body);
      } catch (error) {
        console.error("Error fetching team details:", error);
      }
    };

    fetchTeamDetails();
    return () => {
      setTeamData(null);
    };
  }, [teamId]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          `${countryAPI}/${teamData.country_id}`
        );
        setCountry(response.data.body.name);
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    };

    if (teamData) {
      fetchCountry();
    }
  }, [teamData, countryAPI]);

  if (!teamData || !country) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">View Team Details</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowDetails(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Go Back
          </button>
        </div>
      </div>
      <div className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]">
        <div className="grid grid-cols-2 gap-y-4">
          <p className="font-bold">Team ID</p>
          <p>{teamData.id}</p>
          <p className="font-bold">Team Name</p>
          <p>{teamData.name}</p>
          <p className="font-bold">Country</p>
          <p>{country}</p>
        </div>
      </div>
    </>
  );
};

export default ViewTeamDetails;
