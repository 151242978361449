import React, { useState, useEffect, useRef } from "react";
import { MdDelete } from "react-icons/md";
import { IoExpand } from "react-icons/io5";
import { FaEdit, FaSearch } from "react-icons/fa";
import axios from "axios";
import AddSimcard from "./addSimcard";
import EditSimCard from "./editSimcard";
import CSVReader from "react-csv-reader";
import ViewTeamLeadDetails from "./viewSimCard";
import DeleteConfirmationPopup from "../deleteConfirmation";
const SimCardTable = ({ nav }) => {
  const simAPI = process.env.REACT_APP_API_SIMCards;
  const mobileOperatorsApi = process.env.REACT_APP_API_MobileOperators;
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [simcardData, setSimcardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [serialNumber, setSerialNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showBatchDeleteConfirmation, setShowBatchDeleteConfirmation] =
    useState(false);
  const [mobileOperators, setMobileOperators] = useState([]);
  const [importError, setImportError] = useState("");
  const [importSuccess, setImportSuccess] = useState("");
  const [selectedSimCards, setSelectedSimCards] = useState(new Set());
  const csvReaderRef = useRef(null);

  const handleDeleteClick = async (userId) => {
    setShowDeleteConfirmation(true);
    setDeleteUserId(userId);
  };
  const fetchMobileOperators = async () => {
    setLoading(true);
    try {
      const response = await axios.get(mobileOperatorsApi);
      setMobileOperators(response.data.body);
    } catch (error) {
      console.error("Error fetching countries:", error);
    } finally {
      setLoading(false);
      fetchSimcardData();
    }
  };
  const toggleSimCardSelection = (simCardId) => {
    setSelectedSimCards((prevSelectedSimCards) => {
      const newSelection = new Set(prevSelectedSimCards);
      if (newSelection.has(simCardId)) {
        newSelection.delete(simCardId);
      } else {
        newSelection.add(simCardId);
      }
      return newSelection;
    });
  };

  // Function to handle select/deselect all
  const toggleSelectAll = () => {
    if (selectedSimCards.size === filteredSimcards.length) {
      setSelectedSimCards(new Set());
    } else {
      setSelectedSimCards(
        new Set(filteredSimcards.map((simcard) => simcard.key))
      );
    }
  };

  const handleConfirmDelete = async () => {
    if (showBatchDeleteConfirmation) {
      try {
        // Assuming `REACT_APP_API_BatchDeleteSimCards` is your batch delete endpoint
        const batchDeleteApi = process.env.REACT_APP_API_BatchDeleteSimCards;

        // For batch delete, send all selected IDs as a payload
        await axios.delete(batchDeleteApi, {
          ids: Array.from(selectedSimCards),
        });

        // Filter out deleted sim cards from simcardData
        setSimcardData((prevData) =>
          prevData.filter((simcard) => !selectedSimCards.has(simcard.key))
        );

        setSelectedSimCards(new Set()); // Clear selection after deletion
      } catch (error) {
        console.error("Error during batch deletion:", error);
      }
    } else {
      try {
        // Single deletion logic as before
        await axios.delete(`${simAPI}/${deleteUserId}`);

        setSimcardData((prevData) =>
          prevData.filter((simcard) => simcard.key !== deleteUserId)
        );
      } catch (error) {
        console.error("Error deleting sim:", error);
      }
    }

    // Hide confirmation after action for both cases
    setShowDeleteConfirmation(false);
    setShowBatchDeleteConfirmation(false);
  };
  const promptBatchDeleteConfirmation = () => {
    setShowBatchDeleteConfirmation(true);
  };
  useEffect(() => {
    fetchMobileOperators();
  }, []);
  const filteredSimcards = simcardData.filter((simcard) => {
    const fullName = `${simcard.first_name || ""} ${simcard.last_name || ""}`;
    const operator = simcard.operator || "";
    const country_name = simcard.country_name || "";
    const prefix = simcard.prefix ? simcard.prefix.toString() : "";
    const msisdn = simcard.msisdn ? simcard.msisdn.toString() : "";
    const iccid = simcard.iccid ? simcard.iccid.toString() : "";
    const imsi = simcard.imsi ? simcard.imsi.toString() : "";

    return (
      fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      operator.toLowerCase().includes(searchQuery.toLowerCase()) ||
      country_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      prefix.includes(searchQuery.toLowerCase()) ||
      msisdn.includes(searchQuery.toLowerCase()) ||
      iccid.includes(searchQuery.toLowerCase()) ||
      imsi.includes(searchQuery.toLowerCase())
    );
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // const handleConfirmDelete = async () => {
  //   try {
  //     await axios.delete(`${simAPI}/${deleteUserId}`);
  //     // Remove the deleted entry from simcardData
  //     setSimcardData((prevData) =>
  //       prevData.filter((simcard) => simcard.key !== deleteUserId)
  //     );
  //     setShowDeleteConfirmation(false);
  //   } catch (error) {
  //     console.error("Error deleting sim:", error);
  //   }
  // };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };
  // const handleFile = async (data, fileInfo) => {
  //   setImportSuccess("");
  //   setImportError("");
  //   let importSuccessCount = 0;
  //   let importErrorCount = 0;

  //   // Create an array to hold all promises of axios requests
  //   const uploadPromises = data.map(async (item) => {
  //     if (!item.mno_id || !item.msisdn) {
  //       setImportError("File not imported, 'mno_id' or 'msisdn' is missing");
  //     } else {
  //       const randomNumber = Math.floor(Math.random() * 1000);
  //       try {
  //         await axios.post(
  //           "https://q90kqsgfsh.execute-api.us-east-1.amazonaws.com/dev/bulk-sims-for-users",
  //           {
  //             mno_id: item.mno_id,
  //             msisdn: item.msisdn,
  //             day_birth: 0,
  //             month_birth: 0,
  //             year_birth: 0,
  //             email: ``,
  //             first_name: ``,
  //             last_name: ``,
  //             password: "",
  //             virtualMSISDN: "",
  //             iccid: "",
  //             imsi: "",
  //           }
  //         );
  //         importSuccessCount++;
  //       } catch (error) {
  //         importErrorCount++;
  //         console.error("An error occurred:", error);
  //       }
  //     }
  //   });

  //   // Wait for all promises to be resolved
  //   await Promise.all(uploadPromises);

  //   // After all requests are completed, update the state with success message if all successful
  //   if (importSuccessCount === data.length) {
  //     setImportSuccess(
  //       `SIM Data for ${importSuccessCount} Imported Successfully`
  //     );
  //   } else if (importErrorCount > 0) {
  //     setImportError(`SIM Data for ${importErrorCount} not imported`);
  //   }
  // };
  const handleFile = async (data, fileInfo) => {
    setImportSuccess("");
    setImportError("");
    let importSuccessCount = 0;
    let importErrorCount = 0;

    // Assuming the endpoint and the request payload structure
    const apiEndpoint =
      "https://q90kqsgfsh.execute-api.us-east-1.amazonaws.com/dev/bulk-sims-for-users";

    // Map the CSV data to your API's expected format
    const formattedData = data.map((item) => ({
      mno_id: item.mno_id,
      msisdn: item.msisdn,
      day_birth: 0,
      month_birth: 0,
      year_birth: 0,
      email: ``,
      first_name: ``,
      last_name: ``,
      password: "",
      virtualMSISDN: "",
      iccid: "",
      imsi: "",
    }));

    // Send the bulk data to your API endpoint
    try {
      const response = await axios.post(apiEndpoint, formattedData);

      if (response.data.body == "Bulk Records Uploaded") {
        setImportSuccess(`Successfully imported all records.`);
      }
      // Assuming the API returns the number of successfully inserted records
      importSuccessCount = response.data.successCount;
      importErrorCount = data.length - importSuccessCount;
    } catch (error) {
      console.error("Bulk import error:", error);
      setImportError("An error occurred during the import process.");
      return;
    }

    // // Update UI based on the operation result
    // if (importSuccessCount > 0) {
    //   setImportSuccess(`Successfully imported ${importSuccessCount} records.`);
    // }
    // if (importErrorCount > 0) {
    //   setImportError(`Failed to import ${importErrorCount} records.`);
    // }
  };

  const fetchSimcardData = async () => {
    setLoading(true);
    try {
      fetch(simAPI)
        .then((res) => res.json())
        .then((res) => {
          const newData = res.body.map((item) => ({
            key: item.id,
            firstName: item.first_name,
            lastName: item.last_name,
            email: item.user_name,
            password: item.password,
            mnoName: item.mnoName,
            number: item.number,
            countryObject: item.countryObject,
            operator: item.operator_name,
            prefix: item.dialing_code,
            msisdn: item.msisdn,
            iccid: item.iccid,
            imsi: item.imsi,
            enabled: item.enabled,
            services: item.services,
            virtualMsisdn: item.virtualMSISDN,
            birthday_day: item.birthday_day,
            birthday_month: item.birthday_month,
            birthday_year: item.birthday_year,
            gender: item.gender,
          }));

          setSimcardData(newData);
        });
    } catch (error) {
      console.error("Error fetching testers:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDownloadSampleFile = () => {
    const sampleFileUrl =
      "https://sims-upload-sample-file.s3.amazonaws.com/BulkSimDataUploadingSample.csv";
    const anchor = document.createElement("a");
    anchor.href = sampleFileUrl;
    anchor.download = "sample.csv";

    document.body.appendChild(anchor);
    anchor.click();

    // Cleanup
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    fetchSimcardData();
  }, [
    showForm,
    setShowForm,
    showEditForm,
    setEditForm,
    importError,
    setImportError,
    importSuccess,
    setImportSuccess,
  ]);

  const handleAddClick = () => {
    setShowForm(true);
  };

  const handleEditClick = (userId) => {
    setEditForm(true);
    setSelectedUserId(userId);
  };

  const handleViewClick = (userId) => {
    setShowDetails(true);
    setSelectedUserId(userId);
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="container mx-auto">
        {showForm ? (
          <AddSimcard setShowForm={setShowForm} />
        ) : showEditForm ? (
          <EditSimCard id={selectedUserId} setEditForm={setEditForm} />
        ) : showDetails ? (
          <ViewTeamLeadDetails
            id={selectedUserId}
            setShowDetails={setShowDetails}
          />
        ) : (
          <>
            <div className="flex flex-col md:flex-row justify-between items-center pt-10 mb-4 pl-10 pr-10">
              <h2 className="text-2xl font-bold">All Sim Cards</h2>
              <div className="flex items-center mt-4 md:mt-0">
                <div className="form-control relative ml-4">
                  <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search"
                    className="pl-10 input input-bordered w-30 md:w-auto"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="flex flex-col items-start ml-4">
                  {" "}
                  {/* Changed from 'ml-8' to 'ml-4' */}
                  <p className="text-[red]">{importError}</p>
                  <p className="text-[green]">{importSuccess}</p>
                </div>
                <label
                  htmlFor="csvFile"
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-8"
                >
                  Upload CSV
                </label>

                <CSVReader
                  onFileLoaded={handleFile}
                  inputId="csvFile"
                  inputStyle={{ display: "none" }}
                  parserOptions={{ header: true, skipEmptyLines: true }}
                />
                {selectedSimCards.size === filteredSimcards.length &&
                  filteredSimcards.length > 0 && (
                    <button
                      onClick={() => setShowBatchDeleteConfirmation(true)} // Updated this line
                      className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-red-500 text-white ml-8"
                    >
                      Delete All
                    </button>
                  )}
                <button
                  onClick={handleAddClick}
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-8"
                >
                  Add Sim Card
                </button>
                <button
                  onClick={handleDownloadSampleFile}
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md border-primaryColor text-primaryColor ml-8"
                >
                  Download Sample File
                </button>
              </div>
            </div>
            <div className="overflow-x-auto ml-10 mr-10 pl-10 pr-10 pb-10 bg-[#FFFFFF]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left">
                      <input
                        type="checkbox"
                        checked={
                          selectedSimCards.size === filteredSimcards.length
                        }
                        onChange={toggleSelectAll}
                      />
                    </th>
                    <th className="px-4 py-2 text-left">Serial No.</th>
                    <th className="px-4 py-2 text-left">Country</th>
                    <th className="px-4 py-2 text-left">Operator</th>
                    <th className="px-4 py-2 text-left">Prefix</th>
                    <th className="px-4 py-2 text-left">MSISDN</th>
                    <th className="px-4 py-2 text-left">ICCID</th>
                    <th className="px-4 py-2 text-left">IMSI</th>

                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredSimcards.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        No results found
                      </td>
                    </tr>
                  ) : (
                    filteredSimcards.map((simcard, index) => (
                      <tr key={simcardData.id}>
                        <td className="border-t border-b border-[#ADA7A7] px-4 py-2 text-left">
                          <input
                            type="checkbox"
                            checked={selectedSimCards.has(simcard.key)}
                            onChange={() => toggleSimCardSelection(simcard.key)}
                          />
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {serialNumber + index}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {simcard.country_name}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {loading
                            ? "Loading..."
                            : simcard.operator || "Unknown"}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {simcard.prefix}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {simcard.msisdn}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {simcard.iccid}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {simcard.imsi}
                        </td>

                        <td className="border-t border-b border-[#ADA7A7] px-4 py-2">
                          <div className="flex justify-center items-center space-x-2">
                            <button
                              onClick={() => handleViewClick(simcard.key)}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <IoExpand color="green" />
                            </button>
                            <button
                              onClick={() => handleEditClick(simcard.key)}
                              className="text-green-500 hover:text-green-700"
                            >
                              <FaEdit color="blue" />
                            </button>
                            <button
                              onClick={() => handleDeleteClick(simcard.key)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <MdDelete color="red" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {showDeleteConfirmation && (
          <DeleteConfirmationPopup
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        )}
        {showBatchDeleteConfirmation && (
          <DeleteConfirmationPopup
            onCancel={() => setShowBatchDeleteConfirmation(false)}
            onConfirm={handleConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default SimCardTable;
