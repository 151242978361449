import React, { useState } from "react";
import axios from "axios";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ChangePassword = ({ setShowChangePassword }) => {
  const apiUrl = process.env.REACT_APP_API_UpdatePassword;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);
  const [errors, setErrors] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [apiResponse, setApiResponse] = useState(""); // State variable to store API response
  const [showResponse, setShowResponse] = useState(false); // State variable to control visibility of response message

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    if (errors.oldPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        oldPassword: "",
      }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Data validation checks
    if (!oldPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        oldPassword: "Old password cannot be empty",
      }));
      return;
    }
    if (!password || !confirmPassword) {
      setErrors({
        password: "Password fields cannot be empty",
        confirmPassword: "Password fields cannot be empty",
      });
      return;
    }
    if (password !== confirmPassword) {
      setErrors({
        password: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
      return;
    }
    // API call to update password
    try {
      const response = await axios.put(apiUrl, {
        userId: user.id,
        oldPassword,
        newPassword: password,
      });
      console.log("Password changed successfully:", response.data);
      setApiResponse(response.data);
      console.log("response.data", response.data);
      setShowResponse(true);
      if (response.data.body === "Password updated successfully") {
        setTimeout(() => {
          setShowResponse(false);
          setShowChangePassword(false);
          const dropdown = document.querySelector(".dropdown-content");
          if (dropdown) {
            dropdown.style.display = "block";
          }
        }, 1000);
      }
      // Optionally, you can show a success message or redirect the user
    } catch (error) {
      console.error("Error changing password:", error.message);
      // Handle error, display error message to the user
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]"
      >
        <div className="flex flex-wrap -mx-4">
          {/* Old Password input */}
          <div className="w-full md:w-1/2 px-4 mb-4 relative">
            <label className="form-control relative">
              <span className="label-text">Old Password</span>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Old Password"
                className="input input-bordered"
                value={oldPassword}
                onChange={handleOldPasswordChange}
              />
              <div
                className="absolute inset-y-0  right-0 top-5  flex items-center pr-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={25} className="text-[gray]" />
                ) : (
                  <AiOutlineEye size={25} className="text-[gray]" />
                )}
              </div>
            </label>
            {errors.oldPassword && (
              <p className="text-[red] text-xs mt-1">{errors.oldPassword}</p>
            )}
          </div>
          {/* New Password input */}
          <div className="w-full md:w-1/2 px-4 mb-4 relative">
            <label className="form-control relative">
              <span className="label-text">New Password</span>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter New Password"
                className="input input-bordered"
                value={password}
                onChange={handlePasswordChange}
              />
              <div
                className="absolute inset-y-0  right-0 top-5  flex items-center pr-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={25} className="text-[gray]" />
                ) : (
                  <AiOutlineEye size={25} className="text-[gray]" />
                )}
              </div>
            </label>
            {errors.password && (
              <p className="text-[red] text-xs mt-1">{errors.password}</p>
            )}
          </div>
          {/* Confirm Password input */}
          <div className="w-full md:w-1/2 px-4 mb-4 relative">
            <label className="form-control relative">
              <span className="label-text">Confirm Password</span>
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                className="input input-bordered"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <div
                className="absolute inset-y-0  right-0 top-5  flex items-center pr-3 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <AiOutlineEyeInvisible size={25} className="text-[gray]" />
                ) : (
                  <AiOutlineEye size={25} className="text-[gray]" />
                )}
              </div>
            </label>
            {errors.confirmPassword && (
              <p className="text-[red] text-xs mt-1">
                {errors.confirmPassword}
              </p>
            )}
          </div>
        </div>

        {/* Submit button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
          >
            Update Password
          </button>
        </div>
        {showResponse && (
          <div className="ml-10 mr-10 text-[green] bg-[#FFFFFF] mt-4 p-4 text-center">
            <p>{apiResponse.body}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default ChangePassword;
