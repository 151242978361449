import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { IoExpand } from "react-icons/io5";
import { FaEdit, FaSearch } from "react-icons/fa";
import AddTeamForm from "./addTeam";
import ViewTeamDetail from "./viewTeamDetails";
import axios from "axios";
import DeleteConfirmationPopup from "../deleteConfirmation";
import EditTeam from "./editTeam";

const UserTable = ({ nav }) => {
  const countryAPI = process.env.REACT_APP_API_Countries;
  const teamsURL = process.env.REACT_APP_API_Team;
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [serialNumber, setSerialNumber] = useState(1);
  const [countries, setCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const getCountryNameById = (countryId) => {
    const country = countries.find((c) => c.id === countryId);
    return country ? country.name : "Unknown";
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = users.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      getCountryNameById(user.country_id)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });
  const handleDeleteClick = async (userId) => {
    setShowDeleteConfirmation(true);
    setDeleteUserId(userId);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${teamsURL}/${deleteUserId}`);
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== deleteUserId)
      );
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    fetchData();
    fetchCountries();
  }, [showForm, setShowForm, showEditForm, setEditForm]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(teamsURL);
      if (!response.ok) {
        throw new Error("Failed to fetch teams");
      }
      const data = await response.json();
      setUsers(data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(countryAPI);
      setCountries(response.data.body);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleAddClick = () => {
    setShowForm(true);
  };

  const handleEditClick = async (userId) => {
    setEditForm(true);
    setSelectedUserId(userId);
  };

  const handleViewClick = async (userId) => {
    setShowDetails(true);
    setSelectedUserId(userId);
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="container mx-auto">
        {showForm ? (
          <AddTeamForm setShowForm={setShowForm} />
        ) : showEditForm ? (
          <EditTeam id={selectedUserId} setEditForm={setEditForm} />
        ) : showDetails ? (
          <ViewTeamDetail
            teamId={selectedUserId}
            setShowDetails={setShowDetails}
          />
        ) : (
          <>
            <div className="flex flex-col md:flex-row justify-between items-center pt-10 mb-4 pl-10 pr-10">
              <h2 className="text-2xl font-bold">All Teams</h2>
              <div className="flex items-center mt-4 md:mt-0">
                <div className="search-bar">
                  <FaSearch className="translate-x-3 transform translate-y-8 text-[gray]" />
                  <input
                    type="text"
                    placeholder="Search"
                    className="pl-10 input input-bordered w-30 md:w-auto "
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <button
                  onClick={handleAddClick}
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-8"
                >
                  Add Team
                </button>
              </div>
            </div>
            <div className="overflow-x-auto ml-10 mr-10 pl-10 pr-10 pb-10 bg-[#FFFFFF]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left">Serial No.</th>
                    <th className="px-4 py-2 text-left">Team Name</th>
                    <th className="px-4 py-2 text-left">Country</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredUsers.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        No teams found
                      </td>
                    </tr>
                  ) : (
                    filteredUsers.map((user, index) => (
                      <tr key={user.id}>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {serialNumber + index}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {user.name}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {getCountryNameById(user.country_id)}
                        </td>
                        <td className="border-t border-b border-[#ADA7A7] px-4 py-2">
                          <div className="flex justify-center items-center space-x-2">
                            <button
                              className="text-blue-500 hover:text-blue-700"
                              onClick={() => handleViewClick(user.id)}
                            >
                              <IoExpand color="green" />
                            </button>
                            <button
                              className="text-green-500 hover:text-green-700"
                              onClick={() => handleEditClick(user.id)}
                            >
                              <FaEdit color="blue" />
                            </button>
                            <button
                              className="text-red-500 hover:text-red-700"
                              onClick={() => handleDeleteClick(user.id)}
                            >
                              <MdDelete color="red" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {showDeleteConfirmation && (
          <DeleteConfirmationPopup
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default UserTable;
