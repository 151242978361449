import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Button,
  Form,
  Input,
  Radio,
  Progress,
  Select,
  message,
  DatePicker,
  Row,
  Col,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
const AddSimCard = ({ setShowForm }) => {
  const countriesApi = process.env.REACT_APP_API_Countries;
  const mobileoOperatorsApi = process.env.REACT_APP_API_MobileOperators;
  const simCardsApi = process.env.REACT_APP_API_SIMCards;
  const twoColors = {
    "0%": "#108ee9",
    "100%": "#87d068",
  };
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [firstNameFacebook, setFirstNameFacebook] = useState("");
  const [lastNameFacebook, setLastNameFacebook] = useState("");
  const [passwordFacebook, setPasswordFacebook] = useState("S3ctretPa$$word");
  const [genderFacebook, setGenderFacebook] = useState("");
  const [birthDateFacebook, setBirthDateFacebook] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [progress, setProgress] = useState(0);
  const [country, setCountry] = useState("");
  const [mnoName, setMNOName] = useState("");
  const [mnoNameList, setMNONameList] = useState();
  const [dialingCode, setDialingCode] = useState("");
  const [msisdn, setMSISDN] = useState("");
  const [iccid, setICCID] = useState("");
  const [virtualMSISDN, setVirtualMSISDN] = useState();
  const [imsi, setIMSI] = useState("");
  const [countryList, setCountryList] = useState();
  const [countryObject, setCountryObject] = useState();
  useEffect(() => {
    const dataUrl = countriesApi;
    fetch(dataUrl)
      .then((res) => res.json())
      .then((res) => {
        setCountryList(res.body);
      });
  }, []);
  const handleFacbookSingleEntry = async () => {
    const day = moment(birthDateFacebook).format("D");
    const month = moment(birthDateFacebook).format("M");
    const year = moment(birthDateFacebook).format("YYYY");
    if (
      // genderFacebook == "" ||
      // firstNameFacebook == "" ||
      // lastNameFacebook == "" ||
      // passwordFacebook == "" ||
      // birthDateFacebook == "" ||
      country === "" ||
      mnoName === "" ||
      dialingCode === "" ||
      msisdn === ""
      // iccid === "" ||
      // virtualMSISDN === "" ||
      // imsi === ""
    ) {
      messageApi.error("Kindly Fill All Fields");
    } else {
      const progressInterval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 90 ? prevProgress + 5 : 90
        );
      }, 300);
      setLoadingFacebook(true);
      messageApi.open({
        type: "loading",
        content: "Action in progress..",
        duration: 5,
      });

      const randomNumber = Math.floor(Math.random() * 1000);
      const createitems = simCardsApi;
      const data = {
        day_birth: day,
        month_birth: month,
        year_birth: year,
        email: `${firstNameFacebook}${lastNameFacebook}${randomNumber}@gmail.com`,
        first_name: firstNameFacebook,
        last_name: lastNameFacebook,
        password: passwordFacebook,
        mno_id: mnoName,
        virtualMSISDN: virtualMSISDN,
        gender: genderFacebook,
        msisdn: msisdn,
        iccid: iccid,
        imsi: imsi,
      };
      axios
        .post(createitems, data)
        .then((response) => {
          if (response.status) {
            messageApi.success("Number Registered Successfully");
            setShowForm(false);
          } else {
            messageApi.error("Number Not Registered");
          }
        })
        .catch((error) => {
          messageApi.error("An Error Occurred");
        })
        .finally(() => {
          clearInterval(progressInterval);
          setLoadingFacebook(false);
        });
    }
  };
  const onChange = (date, dateString) => {
    setBirthDateFacebook(dateString);
  };

  const { Option } = Select;

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Add SIM Card</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowForm(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Cancel
          </button>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-4 bg-[#FFFFFF]"
      >
        {contextHolder}
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Country"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                showSearch
                placeholder="Select your country"
                value={country}
                onChange={(value) => {
                  const dataUrl = `${countriesApi}/${value}`;
                  fetch(dataUrl)
                    .then((res) => res.json())
                    .then((res) => {
                      setCountryObject(res.body);
                      setCountry(res.body.name);
                      setDialingCode(res.body.dialing_code);
                      const dataUrl = `${mobileoOperatorsApi}?country=${value}`;
                      fetch(dataUrl)
                        .then((res) => res.json())
                        .then((res) => {
                          setMNONameList(res.body);
                        });
                    });
                  setMNOName("");
                }}
                style={{ width: "100%" }}
              >
                {countryList?.map((country) => (
                  <Option key={country?.id} value={country?.id}>
                    {country?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="MNO Name"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                showSearch
                placeholder="Select your MNO Name"
                value={mnoName}
                onChange={(value) => setMNOName(value)}
                style={{ width: "100%" }}
                disabled={!country}
              >
                {mnoNameList?.map((mno) => (
                  <Option key={mno.id} value={mno.id}>
                    {mno.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Dialing Code"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                showSearch
                placeholder="Select your Dialing Code"
                value={dialingCode}
                onChange={(value) => setDialingCode(value)}
                style={{ width: "100%" }}
                disabled={!country}
              >
                <Option
                  key={countryObject?.id}
                  value={countryObject?.dialing_code}
                >
                  {countryObject?.dialing_code}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="MSISDN"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="Enter your MSISDN"
                value={msisdn}
                onChange={(event) => {
                  setMSISDN(event.target.value);
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="ICCID"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="Enter your ICCID"
                value={iccid}
                onChange={(event) => {
                  setICCID(event.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Virtual MSISDN"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                mode="tags"
                placeholder="Enter or select Virtual MSISDN"
                value={virtualMSISDN}
                onChange={(values) => {
                  setVirtualMSISDN(values);
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="IMSI"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="Enter your IMSI"
                value={imsi}
                onChange={(event) => {
                  setIMSI(event.target.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="First Name"
              required
              tooltip="This is a required field"
            >
              <Input
                type="text"
                placeholder="Enter First Name"
                value={firstNameFacebook}
                onChange={(event) => {
                  setFirstNameFacebook(event.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Last Name"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="Enter Last Name"
                value={lastNameFacebook}
                onChange={(event) => {
                  setLastNameFacebook(event.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item label="Gender" name="requiredMarkValueFacebook">
              <Radio.Group
                style={{ width: "100%" }}
                onChange={(event) => {
                  setGenderFacebook(event.target.value);
                }}
              >
                <Radio.Button style={{ width: "50%" }} value="1">
                  Female
                </Radio.Button>
                <Radio.Button style={{ width: "50%" }} value="2">
                  Male
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Birth Date"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <DatePicker style={{ width: "100%" }} onChange={onChange} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Password"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type={showPassword ? "text" : "password"}
                value={passwordFacebook}
                onChange={onChange}
                disabled
                addonAfter={
                  <Button
                    type="text"
                    icon={
                      showPassword ? <EyeInvisibleOutlined /> : <EyeTwoTone />
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  />
                }
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Form.Item>
          <div className="flex justify-end">
            <Button
              onClick={() => {
                handleFacbookSingleEntry();
              }}
              type="primary"
              className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
            >
              Add SIM Card
            </Button>
          </div>
        </Form.Item>
        {loadingFacebook && (
          <div>
            <Progress percent={progress} strokeColor={twoColors} />
          </div>
        )}
      </Form>
    </>
  );
};

export default AddSimCard;
