import React, { useEffect, useState } from "react";
import { Button, Layout, Menu, theme, Tabs } from "antd";
import Testers from "./tester-teamlead/testers";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const TeamleadDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");

  const navigate = useNavigate();
  const { Header, Content, Footer } = Layout;

  const onLogout = () => {
    localStorage.removeItem("setupTime");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
    console.log("Logout clicked");
  };

  const handleMenuSelect = (key) => {
    setSelectedMenuItem(key);
  };

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={items}
          onClick={({ key }) => handleMenuSelect(key)}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
        <Button type="link" onClick={onLogout} icon={<LogoutOutlined />} />
      </Header>
      <Content
        style={{
          padding: "0 48px",
        }}
      >
        <View1 /> {/* Render View1 here */}
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        OTP Generator ©{new Date().getFullYear()} Created by EON Intelligence
      </Footer>
    </Layout>
  );
};

const View1 = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onChangeTab = (key) => {
    setSelectedMenuTabItem(key);
  };
  const [tabPosition, setTabPosition] = useState("left");
  const [selectedMenuTabItem, setSelectedMenuTabItem] = useState("1");
  useEffect(() => {
    const handleResize = () => {
      // Switch to horizontal tabs for small screens
      if (window.innerWidth <= 768) {
        setTabPosition("top");
      } else {
        // Default to vertical tabs for larger screens
        setTabPosition("left");
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        padding: 24,
        minHeight: 380,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <Tabs
        activeKey={selectedMenuTabItem}
        onChange={onChangeTab}
        type="card"
        tabPosition={tabPosition}
        style={{ minHeight: 200 }}
      >
        <Tabs.TabPane key="1" tab="All Testers">
          <Testers />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const items = [
  {
    key: "1",
    label: `EON Testing Tool`,
    component: View1,
  },
];

export default TeamleadDashboard;
