import React, { useState, useEffect } from "react";
import axios from "axios";

const ViewTeamLeadDetails = ({ setShowDetails, teamId }) => {
  const [teamleadData, setTeamleadData] = useState(null);
  const teamleadAPI = process.env.REACT_APP_API_Teamleads;

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const response = await axios.get(`${teamleadAPI}/${teamId}`);
        setTeamleadData(response.data.body);
      } catch (error) {
        console.error("Error fetching team details:", error);
      }
    };

    fetchTeamDetails();
    return () => {
      setTeamleadData(null);
    };
  }, [teamId]);

  if (!teamleadData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">View Teamlead Details</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowDetails(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Go Back
          </button>
        </div>
      </div>
      <div className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]">
        <div className="grid grid-cols-2 gap-y-4">
          <p className="font-bold">Teamlead ID</p>
          <p>{teamleadData.id}</p>
          <p className="font-bold">Teamlead Name</p>
          <p>{teamleadData.first_name + " " + teamleadData.last_name}</p>
          <p className="font-bold">Contact Number</p>
          <p>{teamleadData.contact_number}</p>
          <p className="font-bold">Email</p>
          <p>{teamleadData.email}</p>
        </div>
      </div>
    </>
  );
};

export default ViewTeamLeadDetails;
