import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

const Statistics = ({ nav }) => {
  const apiurl = process.env.REACT_APP_API_TesterStatistics;
  const [showForm, setShowForm] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serialNumber, setSerialNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers =
    users?.filter(
      (user) =>
        user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.otps_count.toString().includes(searchQuery.toLowerCase())
    ) ?? [];

  useEffect(() => {
    fetchData();
  }, [showForm, setShowForm]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiurl);
      if (!response.ok) {
        throw new Error("Failed to fetch stats");
      }
      const data = await response.json();
      console.log("data::", data);
      console.log("data::", data.body);
      setUsers(data);
    } catch (error) {
      console.error("Error fetching stats:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="container mx-auto">
        <>
          <div className="flex flex-col md:flex-row justify-between items-center pt-10 mb-4 pl-10 pr-10">
            <h2 className="text-2xl font-bold">Tester Statistics</h2>
            <div className="flex items-center mt-4 md:mt-0">
              <div className="search-bar">
                <FaSearch className="translate-x-3 transform translate-y-8 text-[gray]" />
                <input
                  type="text"
                  placeholder="Search"
                  className="pl-10 input input-bordered w-30 md:w-auto "
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div className="overflow-x-auto ml-10 mr-10 pl-10 pr-10 pb-10 bg-[#FFFFFF]">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Serial No.</th>
                  <th className="px-4 py-2 text-left">Tester Name</th>
                  <th className="px-4 py-2 text-left">
                    Tests recieved this month
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="8" className="text-center py-4">
                      Loading...
                    </td>
                  </tr>
                ) : filteredUsers.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="text-center py-4">
                      No statistics found
                    </td>
                  </tr>
                ) : (
                  filteredUsers.map((user, index) => (
                    <tr key={user.id}>
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {serialNumber + index}
                      </td>
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.first_name + " " + user.last_name}
                      </td>
                      <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                        {user.otps_count}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </>
      </div>
    </div>
  );
};

export default Statistics;
