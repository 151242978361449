import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
const AddTeamLead = ({ setShowForm }) => {
  const teamsURL = process.env.REACT_APP_API_Team;
  const teamleadAPI = process.env.REACT_APP_API_Teamleads;
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact_number, setContactNumber] = useState("");
  const [gender, setGender] = useState("");
  const [team, setTeam] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [teams, setTeams] = useState([]);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    gender: "",
    team: "",
    confirmPassword: "",
  });
  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(teamsURL);
      setTeams(response.data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (errors.first_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
      }));
    }
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (errors.last_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "",
      }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
    if (errors.contact_number) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact_number: "",
      }));
    }
  };

  const handleGenderChange = (value) => {
    setGender(value);
    if (errors.gender) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: "",
      }));
    }
  };

  const handleTeamChange = (e) => {
    setTeam(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, team: "" }));
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!password.trim()) {
      newErrors.password = "Please enter Password";
    } else if (password.trim().length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    const contactNumberRegex = /^(?:\+?92|0)\d{10}$/; // Regex for Pakistani phone numbers
    if (!contact_number.trim()) {
      newErrors.contact_number = "Please enter Contact Number";
    } else if (!contactNumberRegex.test(contact_number.trim())) {
      newErrors.contact_number =
        "Please enter a valid contact number (e.g., +92331514193 or 03313423987)";
    }

    if (!first_name.trim()) {
      newErrors.first_name = "Please enter First Name";
    }
    if (!last_name.trim()) {
      newErrors.last_name = "Please enter Last Name";
    }
    if (!email.trim()) {
      newErrors.email = "Please enter Email";
    }
    if (!gender) {
      newErrors.gender = "Please select Gender";
    }
    if (!team) {
      newErrors.team = "Please select Team";
    }
    if (password.trim() !== confirmPassword.trim()) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const selectedTeam = teams.find((t) => t.name === team);
      if (!selectedTeam) {
        throw new Error("Selected team not found.");
      }

      const team_id = selectedTeam.id;
      const response = await axios.post(teamleadAPI, {
        first_name,
        last_name,
        email,
        password,
        role_id: 2,
        gender,
        contact_number,
        team_id,
      });
      setShowForm(false);
    } catch (error) {
      console.error("Error adding team lead:", error);
      setErrors({ general: "Failed to add team lead" });
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Add Team Lead</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowForm(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Cancel
          </button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]"
      >
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="form-control">
              <span className="label-text">First Name</span>
              <input
                type="text"
                placeholder="Enter First Name"
                className="input input-bordered"
                value={first_name}
                onChange={handleFirstNameChange}
              />
              {errors.first_name && (
                <p className="text-[red] text-xs mt-1">{errors.first_name}</p>
              )}
            </label>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="form-control">
              <span className="label-text">Last Name</span>
              <input
                type="text"
                placeholder="Enter Last Name"
                className="input input-bordered"
                value={last_name}
                onChange={handleLastNameChange}
              />
              {errors.last_name && (
                <p className="text-[red] text-xs mt-1">{errors.last_name}</p>
              )}
            </label>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="form-control">
              <span className="label-text">Email</span>
              <input
                type="email"
                placeholder="Enter Email"
                className="input input-bordered"
                value={email}
                onChange={handleEmailChange}
              />
              {errors.email && (
                <p className="text-[red] text-xs mt-1">{errors.email}</p>
              )}
            </label>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="form-control">
              <span className="label-text">Contact Number</span>
              <input
                type="tel"
                placeholder="Enter Contact Number"
                className="input input-bordered"
                value={contact_number}
                onChange={handleContactNumberChange}
              />
              {errors.contact_number && (
                <p className="text-[red] text-xs mt-1">
                  {errors.contact_number}
                </p>
              )}
            </label>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-4 relative">
            <label className="form-control relative">
              <span className="label-text">Password</span>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                className="input input-bordered pr-10"
                value={password}
                onChange={handlePasswordChange}
              />
              <div
                className="absolute inset-y-0  right-0 top-5  flex items-center pr-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={25} className="text-[gray]" />
                ) : (
                  <AiOutlineEye size={25} className="text-[gray]" />
                )}
              </div>
            </label>
            {errors.password && (
              <p className="text-[red] text-xs mt-1">{errors.password}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-4 mb-4 relative">
            <label className="form-control relative">
              <span className="label-text">Confirm Password</span>
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                className="input input-bordered"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <div
                className="absolute inset-y-0  right-0 top-5  flex items-center pr-3 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <AiOutlineEyeInvisible size={25} className="text-[gray]" />
                ) : (
                  <AiOutlineEye size={25} className="text-[gray]" />
                )}
              </div>
            </label>
          </div>

          <div className="w-full md:w-1/2 px-4 mb-4">
            <div className="form-control">
              <span className="label-text">Gender</span>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="male"
                    checked={gender === "male"}
                    onChange={() => handleGenderChange("male")}
                  />
                  <span className="ml-2">Male</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="female"
                    checked={gender === "female"}
                    onChange={() => handleGenderChange("female")}
                  />
                  <span className="ml-2">Female</span>
                </label>
              </div>
              {errors.gender && (
                <p className="text-[red] text-xs mt-1">{errors.gender}</p>
              )}
            </div>
          </div>

          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="form-control">
              <span className="label-text">Select Team</span>
              <select
                className="select select-bordered"
                value={team}
                onChange={handleTeamChange}
              >
                <option value="">Select Team</option>
                {loading ? (
                  <option disabled>Loading...</option>
                ) : (
                  teams.map((team) => (
                    <option key={team.id} value={team.name}>
                      {team.name}
                    </option>
                  ))
                )}
              </select>
              {errors.team && (
                <p className="text-[red] text-xs mt-1">{errors.team}</p>
              )}
            </label>
          </div>
        </div>

        {errors.general && (
          <p className="text-red-500 text-xs mt-1">{errors.general}</p>
        )}
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
          >
            Add Team Lead
          </button>
        </div>
      </form>
    </>
  );
};

export default AddTeamLead;
