import "./App.css";
import Login from "./components/login";
import AddTester from "./components/team/addTeam";
import TeamLeadDashboard from "./components/teamleadDashboard";
import TesterDashboard from "./components/user/testerDashboard";
import Navbar from "./components/admin/dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/add-testers" element={<AddTester />} />
        <Route path="/dashboard" element={<Navbar />} />
        <Route path="/teamleadDashboard" element={<TeamLeadDashboard />} />
        <Route path="/testerDashboard" element={<TesterDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
