import React, { useState, useEffect } from "react";
import axios from "axios";

const AddUserForm = ({ setShowForm }) => {
  const countryAPI = process.env.REACT_APP_API_Countries;
  const teamsURL = process.env.REACT_APP_API_Team;

  const [name, setName] = useState("");
  const [country_id, setCountry_id] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    country_id: "",
    general: "",
  });
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    setLoading(true);
    try {
      const response = await axios.get(countryAPI);
      setCountries(response.data.body);
    } catch (error) {
      console.error("Error fetching countries:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTeamNameChange = (e) => {
    setName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleCountryChange = (e) => {
    setCountry_id(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, country_id: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.trim() && !country_id.trim()) {
      newErrors.name = "Please enter the team name";
      newErrors.country_id = "Please select the country";
      setErrors(newErrors);
      return;
    }
    if (!name.trim()) {
      newErrors.name = "Please enter the team name";
      setErrors(newErrors);
      return;
    }
    if (!country_id.trim()) {
      newErrors.country_id = "Please select the country";
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(teamsURL, { name, country_id });
      if (response.status === 200) {
        setShowForm(false);
      } else {
        throw new Error("Failed to add team");
      }
    } catch (error) {
      console.error("Error adding team:", error);
      setErrors({ general: "Failed to add team" });
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Add Team</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowForm(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Cancel
          </button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]"
      >
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text text-base">Team Name</span>
          </div>
          <input
            type="text"
            placeholder="Enter Team Name"
            className="input input-bordered w-full max-w-xs"
            value={name}
            onChange={handleTeamNameChange}
          />
          {errors.name && <p className="text-[red] text-xs">{errors.name}</p>}
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text text-base">Team country</span>
          </div>
          <select
            className="select select-bordered w-full max-w-xs"
            value={country_id}
            onChange={handleCountryChange}
          >
            <option value="">Select Country</option>
            {loading ? (
              <option disabled>Loading...</option>
            ) : (
              countries.map((country_id) => (
                <option key={country_id.id} value={country_id.id}>
                  {country_id.name}
                </option>
              ))
            )}
          </select>
          {errors.country_id && (
            <p className="text-[red] text-xs">{errors.country_id}</p>
          )}
        </label>
        {errors.general && (
          <p className="text-[red] text-xs">{errors.general}</p>
        )}
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
          >
            Add Team
          </button>
        </div>
      </form>
    </>
  );
};

export default AddUserForm;
