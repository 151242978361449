import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  message,
  DatePicker,
  Row,
  Col,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
const EditSimCard = ({ setEditForm, id }) => {
  const mobileoOperatorsApi = process.env.REACT_APP_API_MobileOperators;
  const countriesApi = process.env.REACT_APP_API_Countries;
  const simCardsApi = process.env.REACT_APP_API_SIMCards;

  const [countryList, setCountryList] = useState();
  const [apiData, setApiData] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();
  const [birthdayYear, setBirthdayYear] = useState("");
  const [birthdayMonth, setBirthdayMonth] = useState("");
  const [birthdayDay, setBirthdayDay] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [countryObject, setCountryObject] = useState("");
  const [mnoName, setMnoName] = useState("");
  const [mnoList, setMnoList] = useState();
  const [diallingCode, setDiallingCode] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [imsi, setImsi] = useState("");
  const [iccid, setIccid] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [virtualMsisdn, setVirtualMsisdn] = useState("");
  const [birthDate, setBirthDate] = useState();
  const [gender, setGender] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [form] = Form.useForm();
  const [mnoData, setMnoData] = useState(null);
  const { Option } = Select;
  const onChangeDate = (date, dateString) => {
    console.log("datestring", dateString);
    setBirthDate(dateString);
    setIsModified(true);
  };
  useEffect(() => {
    const dataUrl = countriesApi;
    fetch(dataUrl)
      .then((res) => res.json())
      .then((res) => {
        setCountryList(res.body);
      });
  }, []);
  useEffect(() => {
    const dataUrl = `${simCardsApi}/${id}`;
    fetch(dataUrl)
      .then((res) => res.json())
      .then((res) => {
        console.log("res.body[0]", res.body);
        setApiData(res.body);
      });
    axios
      .get(`${mobileoOperatorsApi}/${mnoName}`)
      .then((response) => {
        console.log("esponse.data.body", response.data.body);
        setMnoData(response.data.body);
      })
      .catch((error) => {
        console.error("Error fetching mobile operator data:", error);
      });
  }, [id, mnoName]);

  useEffect(() => {
    if (apiData) {
      setFirstName(apiData.first_name);
      setLastName(apiData.last_name);
      setCountry(apiData.country);
      setMnoName(apiData.mno_id);
      if (countryList && apiData) {
        const countryId = mnoData.country_id;
        const mnoCountry = countryList.find(
          (country) => country.id === countryId
        );
        if (mnoCountry) {
          setCountry(mnoCountry.name);
          setDiallingCode(mnoCountry.dialing_code);
        }
      }
      setDiallingCode(apiData.dialing_code);
      setMsisdn(apiData.msisdn);
      setImsi(apiData.imsi);
      setIccid(apiData.iccid);
      setPassword(apiData.password);
      setVirtualMsisdn(apiData.virtualMSISDN);
      setGender(apiData.gender);
      setBirthdayDay(String(apiData.day_birth));
      setBirthdayMonth(String(apiData.month_birth));
      setBirthdayYear(String(apiData.year_birth));
    }
  }, [apiData, setApiData, countryList, setCountryList]);

  const dateString = `${birthdayYear.toString()}-${birthdayMonth.toString()}-${birthdayDay.toString()}`;
  const defaultDate = moment(dateString, "YYYY-M-D");
  console.log("defaultDate", defaultDate);
  const updateAllFields = async () => {
    const day = moment(birthDate).format("D");
    const month = moment(birthDate).format("M");
    const year = moment(birthDate).format("YYYY");
    const updateUrl = `${simCardsApi}/${id}`;
    const updatedData = {
      email: userName,
      first_name: firstName,
      iccid: iccid,
      imsi: imsi,
      last_name: lastName,
      mno_id: mnoName,
      msisdn: msisdn,
      password: password,
      virtualMSISDN: virtualMsisdn,
      day_birth: day,
      month_birth: month,
      year_birth: year,
      gender: gender,
    };

    try {
      const response = await fetch(updateUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        console.log("Data updated successfully");
        messageApi.success("Data updated successfully");
        setEditForm(false);
        setIsModified(false);
      } else {
        console.error("Failed to update data");
        messageApi.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const onChange = () => {};

  if (apiData === null) {
    return <p>Loading..</p>;
  }
  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Edit SIM Card</h2>
        <div className="flex items-center">
          <button
            onClick={() => setEditForm(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Cancel
          </button>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-4 bg-[#FFFFFF]"
      >
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Country"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                showSearch
                placeholder="Select your country"
                value={country}
                onChange={(value) => {
                  const dataUrl = `${countriesApi}/${value}`;
                  fetch(dataUrl)
                    .then((res) => res.json())
                    .then((res) => {
                      setCountryObject(res.body);
                      setCountry(res.body.name);
                      setDiallingCode(res.body.dialing_code);
                      const dataUrl = `${mobileoOperatorsApi}?country=${value}`;
                      fetch(dataUrl)
                        .then((res) => res.json())
                        .then((res) => {
                          setMnoList(res.body);
                        });
                    });
                  setMnoName("");
                  setIsModified(true);
                  // setDialingCode('');
                  // Reset dialing code when the country changes
                }}
                style={{ width: "100%" }}
              >
                {countryList?.map((country) => (
                  <Option key={country?.id} value={country?.id}>
                    {country?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="MNO Name"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                showSearch
                placeholder="Select your MNO Name"
                value={mnoName}
                onChange={(value) => {
                  setMnoName(value);
                  setIsModified(true);
                }}
                style={{ width: "100%" }}
                disabled={!country} // Disable when no country is selected
              >
                {mnoList?.map((mno) => (
                  <Option key={mno.id} value={mno.id}>
                    {mno.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Dialing Code"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                showSearch
                placeholder="Select your Dialing Code"
                value={diallingCode}
                onChange={(value) => {
                  setDiallingCode(value);
                  setIsModified(true);
                }}
                style={{ width: "100%" }}
                disabled={!country} // Disable when no country is selected
              >
                {/* {countryOptions[country]?.dialingCodes?.map((code) => ( */}
                <Option
                  key={countryObject?.id}
                  value={countryObject?.dialing_code}
                >
                  {countryObject?.dialing_code}
                </Option>
                {/* ))} */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="MSISDN"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="Enter your MSISDN"
                value={msisdn}
                onChange={(event) => {
                  setMsisdn(event.target.value);
                  setIsModified(true);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="ICCID"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="Enter your ICCID"
                value={iccid}
                onChange={(event) => {
                  setIccid(event.target.value);
                  setIsModified(true);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Virtual MSISDN"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                mode="tags"
                placeholder="Enter or select Virtual MSISDN"
                value={virtualMsisdn}
                onChange={(values) => {
                  setVirtualMsisdn(values);
                  setIsModified(true);
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="IMSI"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="Enter your IMSI"
                value={imsi}
                onChange={(event) => {
                  setImsi(event.target.value);
                  setIsModified(true);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item label="Gender" name="requiredMarkValueFacebook">
              <Radio.Group
                style={{ width: "100%" }}
                defaultValue={gender}
                onChange={(event) => {
                  setGender(event.target.value);
                  setIsModified(true);
                }}
              >
                <Radio.Button style={{ width: "50%" }} value="1">
                  Female
                </Radio.Button>
                <Radio.Button style={{ width: "50%" }} value="2">
                  Male
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="First Name"
              required
              tooltip="This is a required field"
            >
              <Input
                type="text"
                placeholder="input placeholder"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                  setIsModified(!true);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Last Name"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type="text"
                placeholder="input placeholder"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                  setIsModified(!true);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Birth Date"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <DatePicker
                defaultValue={defaultDate}
                style={{ width: "100%" }}
                onChange={onChangeDate}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label="Password"
              required
              tooltip={{
                title: "This is a required field",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={onChange}
                disabled
                addonAfter={
                  <Button
                    type="text"
                    icon={
                      showPassword ? <EyeInvisibleOutlined /> : <EyeTwoTone />
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  />
                }
              />
            </Form.Item>
            <div className="flex justify-end">
              <Button
                onClick={updateAllFields}
                disabled={!isModified}
                style={{
                  backgroundColor: isModified ? "#4CAF50" : "#808080",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: isModified ? "pointer" : "not-allowed",
                }}
                type="primary"
                className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
              >
                Update
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditSimCard;
