import React, { useState } from "react";
import axios from "axios";

const AddApplication = ({ setShowForm }) => {
  const apiUrl = process.env.REACT_APP_API_Applications;
  const [name, setName] = useState("");
  const [app_type, setApp_type] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    app_type: "",
    general: "",
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleAppTypeChange = (e) => {
    setApp_type(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, app_type: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.trim() && !app_type.trim()) {
      newErrors.name = "Please enter the application name";
      newErrors.app_type = "Please select the app type";
      setErrors(newErrors);
      return;
    }
    if (!name.trim()) {
      newErrors.name = "Please enter the application name";
      setErrors(newErrors);
      return;
    }
    if (!app_type.trim()) {
      newErrors.app_type = "Please select the app type";
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(apiUrl, { name, app_type, visible: 1 });
      if (response.status === 200) {
        setShowForm(false);
      } else {
        throw new Error("Failed to add team");
      }
    } catch (error) {
      console.error("Error adding team:", error);
      setErrors({ general: "Failed to add team" });
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pt-10 mb-4 pl-10 pr-10">
        <h2 className="text-2xl font-bold">Add Application</h2>
        <div className="flex items-center">
          <button
            onClick={() => setShowForm(false)}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-[white] text-primaryColor ml-8"
          >
            Cancel
          </button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-10 bg-[#FFFFFF]"
      >
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text text-base">Application Name</span>
          </div>
          <input
            type="text"
            placeholder="Enter Team Name"
            className="input input-bordered w-full max-w-xs"
            value={name}
            onChange={handleNameChange}
          />
          {errors.name && <p className="text-[red] text-xs">{errors.name}</p>}
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text text-base">Application Type</span>
          </div>
          <select
            className="select select-bordered w-full max-w-xs"
            value={app_type}
            onChange={handleAppTypeChange}
          >
            <option value="">Select Application</option>
            <option value="Web">Web</option>
            <option value="Android">Android</option>
          </select>

          {errors.app_type && (
            <p className="text-[red] text-xs">{errors.app_type}</p>
          )}
        </label>
        {errors.general && (
          <p className="text-[red] text-xs">{errors.general}</p>
        )}
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
          >
            Add Application
          </button>
        </div>
      </form>
    </>
  );
};

export default AddApplication;
