import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { IoExpand } from "react-icons/io5";
import { FaEdit, FaSearch } from "react-icons/fa";
import axios from "axios";
import AddTesterForm from "./addTester";
import EditTesterForm from "./editTester";
import ViewTestersDetails from "./viewTesterDetails";
import DeleteConfirmationPopup from "../deleteConfirmation";
const apiUrl = process.env.REACT_APP_API_Testers;

const TestersTable = ({ nav }) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testers, setTesters] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [serialNumber, setSerialNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const filteredTesters = testers.filter((tester) => {
    const fullName = tester.first_name + " " + tester.last_name;
    return (
      fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tester.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tester.contact_number.includes(searchQuery) ||
      tester.gender.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tester.team_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  useEffect(() => {
    fetchData();
  }, [showForm, setShowForm, showEditForm, setEditForm]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch teams");
      }
      const data = await response.json();
      setTesters(data.body);
    } catch (error) {
      console.error("Error fetching tems:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddClick = () => {
    setShowForm(true);
  };

  const handleEditClick = (userId) => {
    setEditForm(true);
    setSelectedUserId(userId);
  };

  const handleViewClick = (userId) => {
    setShowDetails(true);
    setSelectedUserId(userId);
  };

  const handleDeleteClick = async (userId) => {
    setShowDeleteConfirmation(true);
    setDeleteUserId(userId);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/${deleteUserId}`);
      setTesters((prevUsers) =>
        prevUsers.filter((user) => user.id !== deleteUserId)
      );
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="container mx-auto">
        {showForm ? (
          <AddTesterForm setShowForm={setShowForm} />
        ) : showEditForm ? (
          <EditTesterForm id={selectedUserId} setEditForm={setEditForm} />
        ) : showDetails ? (
          <ViewTestersDetails
            testerId={selectedUserId}
            setShowDetails={setShowDetails}
          />
        ) : (
          <>
            <div className="flex flex-col md:flex-row justify-between items-center pt-10 mb-4 pl-10 pr-10">
              <h2 className="text-2xl font-bold">All Testers</h2>
              <div className="flex items-center mt-4 md:mt-0">
                <div className="form-control relative ml-4">
                  <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search"
                    className="pl-10 input input-bordered w-30 md:w-auto"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <button
                  onClick={handleAddClick}
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-4 mt-4 md:mt-0"
                >
                  Add Tester
                </button>
              </div>
            </div>

            <div className="overflow-x-auto ml-10 mr-10 pl-10 pr-10 pb-10 bg-[white]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left">Serial No.</th>
                    <th className="px-4 py-2 text-left">Name</th>
                    <th className="px-4 py-2 text-left">Email</th>
                    <th className="px-4 py-2 text-left">Gender</th>
                    <th className="px-4 py-2 text-left">Contact Info</th>
                    <th className="px-4 py-2 text-left">Team Name</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredTesters.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        No Testers found
                      </td>
                    </tr>
                  ) : (
                    filteredTesters.map((tester, index) => (
                      <tr key={tester.id}>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {serialNumber + index}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {tester.first_name + " " + tester.last_name}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {tester.email}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {tester.gender}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {tester.contact_number}
                        </td>
                        <td className="border-t border-b  border-[#ADA7A7] px-4 py-2 text-left">
                          {tester.team_name}
                        </td>
                        <td className="border-t border-b border-[#ADA7A7] px-4 py-2">
                          <div className="flex justify-center items-center space-x-2">
                            <button
                              onClick={() => handleViewClick(tester.id)}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <IoExpand color="green" />
                            </button>
                            <button
                              onClick={() => handleEditClick(tester.id)}
                              className="text-green-500 hover:text-green-700"
                            >
                              <FaEdit color="blue" />
                            </button>
                            <button
                              onClick={() => handleDeleteClick(tester.id)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <MdDelete color="red" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {showDeleteConfirmation && (
          <DeleteConfirmationPopup
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default TestersTable;
